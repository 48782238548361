<template>
	<ManageYourAccountTemplate v-if="pageLoaded" :page-title="t('title')">
		<BRow>
			<BCol>
				<MemberAddress
					:address="memberAddress"
					:can-edit="canEdit"
					:user="member"
					automation-id="memberAddress"
					data-testid="memberAddress"
					@click="onClickAddress"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<MemberEmail
					:email="member.id"
					automation-id="memberEmail"
					data-testid="memberEmail"
					@click="onClickEmail"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<MemberLanguage
					:language="member.preferredLanguage"
					automation-id="memberLanguage"
					data-testid="memberLanguage"
					@click="onClickLanguage"
				/>
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import store from '@/store';
import router from '@/router/index';
// routes
import {
	DASHBOARD,
	MANAGE_YOUR_ACCOUNT,
	CHANGE_EMAIL,
	CHANGE_LANGUAGE,
	CHANGE_ADDRESS
} from '@/constants/Routes';
// pages
import ManageYourAccountTemplate from '@/pages/manage-your-account/ManageYourAccountTemplate.vue';
// components
import MemberAddress from '@/components/manage-your-account/personal-information/MemberAddress.vue';
import MemberEmail from '@/components/manage-your-account/personal-information/MemberEmail.vue';
import MemberLanguage from '@/components/manage-your-account/personal-information/MemberLanguage.vue';
// models
import ContactInfo from '@/models/ContactInfo';
import MemberCardInfo from '@/models/MemberCardInfo';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;

const memberAddress = ref(null);
const member = ref(null);
const pageLoaded = ref(false);
const canEdit =
	sessionStorage.getItem('updateMemberProfile')?.toLowerCase() === 'true' ? true : false;

// methods
function onClickAddress() {
	router.push({ name: CHANGE_ADDRESS });
}
function onClickEmail() {
	router.push({ name: CHANGE_EMAIL });
}
function onClickLanguage() {
	router.push({ name: CHANGE_LANGUAGE });
}

// lifecycle hooks
onMounted(async () => {
	store.dispatch('updateLoading', true);

	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');

	const results = await Promise.all([
		MemberCardInfo.getMemberDetail(email, token, root.$i18n.locale),
		ContactInfo.getUserInfo(email, token, root.$i18n.locale)
	]);
	member.value = results[0];
	memberAddress.value = results[1];

	if (root.$route.params.hasSucceeded) {
		store.dispatch('successBanner', {
			show: true,
			headerText: t('updateSuccessHeader'),
			text: t('updateSuccess')
		});
	}

	useBreadcrumbs(
		[
			{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.manageYourAccount', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
			{ text: t('breadcrumb.personalInformation', 'en') }
		],
		[
			{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.manageYourAccount', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
			{ text: t('breadcrumb.personalInformation', 'fr') }
		]
	);

	pageLoaded.value = true;
	store.dispatch('updateLoading', false);
});
</script>

<i18n lang="json">
{
	"en": {
		"breadcrumb": {
			"home": "Home",
			"manageYourAccount": "Manage your account",
			"personalInformation": "Personal Information"
		},
		"title": "Personal Information",
		"updateSuccessHeader": "Success!",
		"updateSuccess": "Your personal information has been updated."
	},
	"fr": {
		"breadcrumb": {
			"home": "Accueil",
			"manageYourAccount": "Gérer votre compte",
			"personalInformation": "Renseignements personnels"
		},
		"title": "Renseignements personnels",
		"updateSuccessHeader": "Succès!",
		"updateSuccess": "Vos renseignements personnels ont été mis à jour."
	}
}
</i18n>
