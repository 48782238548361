<template>
	<BaseCard automation-id="languagePreference" :title="$t('title')" title-tag="h2">
		<BCardText class="mb-0">
			{{ $t('text.offerInBothLanguages') }}
		</BCardText>
		<BCardText>
			{{ $t('text.whichDoYouPrefer') }}
		</BCardText>

		<h3>{{ $t('label.preferred') }}</h3>
		<p>{{ languagePreference }}</p>

		<BaseButton
			class="mt-2"
			:label="$t('label.change')"
			:automation-id="getAutomationId('changeLanguage')"
			data-testid="btn-change-language"
			@click="$emit('click')"
		/>
	</BaseCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BCardText } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';

export default {
	name: 'MemberLanguage',
	components: {
		BCardText,
		BaseButton,
		BaseCard
	},
	mixins: [IdMixin],

	props: {
		language: {
			type: String,
			required: true
		}
	},

	computed: {
		languagePreference() {
			return this.language === 'en' ? this.$t('label.english') : this.$t('label.french');
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": "Language Preferences",
    "text": {
      "offerInBothLanguages": "We can communicate plan changes in both English and French.",
      "whichDoYouPrefer": "Which language would you like us to use?"
    },
    "label": {
      "preferred": "Primary/Preferred",
      "change": "Change",
      "english": "English",
      "french": "French"
    }
  },
  "fr": {
    "title": "Langue de préférence",
    "text": {
     "offerInBothLanguages": "Nous pouvons communiquer les changements au régime en français et en anglais.",
      "whichDoYouPrefer": "Quelle langue souhaitez-vous que nous utilisions?"
    },
    "label": {
      "preferred": "Langue principale/de préférence",
      "change": "Modifier",
      "english": "Anglais",
      "french": "Français"
    }
  }
}
</i18n>

<style lang="scss" scoped>
h3 {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	margin: 0;
}
</style>
