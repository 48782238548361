<template>
	<BaseCard automation-id="memberAddress" :title="$t('title')" title-tag="h2">
		<div v-if="!canEdit">
			<BaseAlert
				data-testid="permission-alert"
				class="alert-full-width"
				variant="warning"
				alternate
			>
				{{ $t('alert.heading') }}<br />
				<template #description>{{ $t('alert.description') }}</template>
			</BaseAlert>
		</div>

		<BaseDisplayGroup automation-id="memberName" :label="$t('label.name')">
			{{ userName }}
		</BaseDisplayGroup>

		<MailingAddressDisplay
			automation-id="member-address"
			:address-line1="addressLine1"
			:address-line2="addressLine2"
			:address-line3="addressLine3"
			:city="city"
			:province="province"
			:state="state"
			:postal-code="postalCode"
			:zip-code="zipCode"
			:country="country"
		></MailingAddressDisplay>

		<BaseDisplayGroup v-if="phone" automation-id="memberPhone" :label="$t('label.phone')">
			<address>{{ phone }}</address>
		</BaseDisplayGroup>

		<BaseButton
			v-if="canEdit"
			:label="$t('label.change')"
			:automation-id="getAutomationId('changeAddress')"
			data-testid="btn-change-address"
			@click="$emit('click')"
		/>
	</BaseCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import MailingAddressDisplay from '@/components/common/MailingAddressDisplay.vue';
import { formatPhoneNumberWithParentheses } from '@/utils/commonUtils.js';

export default {
	name: 'MemberAddress',
	components: {
		BaseButton,
		BaseCard,
		BaseDisplayGroup,
		BaseAlert,
		MailingAddressDisplay
	},
	mixins: [IdMixin],
	props: {
		address: {
			type: Object,
			required: true,
			description: 'An address object in a format specific to the data returned by an MSS api call.'
		},
		user: {
			type: Object,
			default: () => {},
			required: true
		},
		canEdit: {
			type: Boolean,
			default: false,
			description: 'Can a member edit their address information?  Some groups can not.'
		}
	},

	computed: {
		userName() {
			return this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
		},
		addressLine1() {
			return this.address.addressLine1;
		},
		addressLine2() {
			return this.address.addressLine2 || null;
		},
		addressLine3() {
			return this.address.addressLine3 || null;
		},
		city() {
			return this.address.city;
		},
		province() {
			return this.address.province || null;
		},
		state() {
			return this.address.state || null;
		},
		postalCode() {
			return this.address.postalCode || null;
		},
		zipCode() {
			return this.address.zipCode || null;
		},
		country() {
			return this.address.country || null;
		},
		phone() {
			const phoneNumber = this.address && this.address.phone ? this.address.phone : '';
			return formatPhoneNumberWithParentheses(phoneNumber);
		}
	}
};
</script>

<style lang="scss" scoped>
.alert-full-width {
	// Use negative margins to overlap the card padding.
	margin-left: -15px;
	margin-right: -15px;
	@include media-breakpoint-up(md) {
		// There is more padding on cards on larger screens.
		margin-left: -30px;
		margin-right: -30px;
	}
}

.display-group:first-child {
	margin-top: 0;
}
</style>

<i18n>
{
  "en": {
    "title": "Address and contact information",
    "alert": {
      "heading": "Why can’t I change or update some of my information?",
      "description": "To keep everything consistent, we retrieve information from your employer’s systems. Any updates made to your personal information on their end will be reflected here."
    },
    "label": {
      "name": "Name",
      "phone": "Phone",
      "change": "Edit"
    }
  },
  "fr": {
    "title": "Adresse et coordonnées",
    "alert": {
      "heading": "Pourquoi ne puis-je pas modifier ou mettre à jour certains de mes renseignements?",
      "description": "Pour nous assurer que nos données soient cohérentes, nous tirons nos renseignements du système de l’employeur. Si l’employeur met à jour vos renseignements personnels, ils seront modifiés ici aussi."
    },
    "label": {
      "name": "Nom",
      "phone": "Téléphone",
      "change": "Modifier"
    }
  }
}
</i18n>
