<!-- 
  Format an address for display.  Includes a label.

  Example how to use:

  <mailing-address-display
    automation-id="1"
    address-line1="644 Main St."
    city="Moncton"
    province="NB"
    postal-code="E1A 8J9"
  ></mailing-address-display>
-->

<template>
	<BaseDisplayGroup :automation-id="getAutomationId('addressDisplay')" :label="addressLabel">
		<address>
			{{ addressLine1 }}<br />
			<template v-if="addressLine2">{{ addressLine2 }}<br /></template>
			<template v-if="addressLine3">{{ addressLine3 }}<br /></template>
			<template v-if="isProvinceValid || isStateValid"
				>{{ city }}, {{ displayProvince || displayState }}<br
			/></template>
			<template v-if="country">{{ displayCountry }}<br /></template>
			<template v-if="isPostalCodeValid || isZipCodeValid"
				>{{ displayPostalCode || zipCode }}
			</template>
		</address>
	</BaseDisplayGroup>
</template>

<script>
import IdMixin from '@/mixins/id';
import BaseDisplayGroup from './base/BaseDisplayGroup.vue';
export default {
	name: 'AddressDisplay',
	components: { BaseDisplayGroup },
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			required: false,
			default: null
		},
		addressLine1: {
			type: String,
			required: true
		},
		addressLine2: {
			type: String,
			required: false,
			default: null
		},
		addressLine3: {
			type: String,
			required: false,
			default: null
		},
		city: {
			type: String,
			required: true
		},
		province: {
			type: String,
			required: false,
			default: null
		},
		state: {
			type: String,
			required: false,
			default: null
		},
		postalCode: {
			type: String,
			required: false,
			default: null
		},
		zipCode: {
			type: String,
			required: false,
			default: null
		},
		country: {
			type: String,
			required: false,
			default: null
		}
	},
	data() {
		return {
			provinces: [
				{ value: 'AB', text: this.$t('province.ab') },
				{ value: 'BC', text: this.$t('province.bc') },
				{ value: 'PE', text: this.$t('province.pei') },
				{ value: 'MB', text: this.$t('province.mb') },
				{ value: 'NB', text: this.$t('province.nb') },
				{ value: 'NS', text: this.$t('province.ns') },
				{ value: 'NU', text: this.$t('province.nu') },
				{ value: 'ON', text: this.$t('province.on') },
				{ value: 'QC', text: this.$t('province.qc') },
				{ value: 'SK', text: this.$t('province.sk') },
				{ value: 'NL', text: this.$t('province.nf') },
				{ value: 'NT', text: this.$t('province.tno') },
				{ value: 'YT', text: this.$t('province.yu') }
			],
			countries: [
				{ value: 'CAN', text: this.$t('country.can') },
				{ value: 'US', text: this.$t('country.usa') },
				{ value: 'OTHER', text: this.$t('country.other') }
			],
			states: [
				{ value: 'AK', text: this.$t('state.ak') },
				{ value: 'AL', text: this.$t('state.al') },
				{ value: 'AR', text: this.$t('state.ar') },
				{ value: 'AZ', text: this.$t('state.az') },
				{ value: 'CA', text: this.$t('state.ca') },
				{ value: 'CO', text: this.$t('state.co') },
				{ value: 'CT', text: this.$t('state.ct') },
				{ value: 'DC', text: this.$t('state.dc') },
				{ value: 'DE', text: this.$t('state.de') },
				{ value: 'FL', text: this.$t('state.fl') },
				{ value: 'GA', text: this.$t('state.ga') },
				{ value: 'HI', text: this.$t('state.hi') },
				{ value: 'IA', text: this.$t('state.ia') },
				{ value: 'ID', text: this.$t('state.id') },
				{ value: 'IL', text: this.$t('state.il') },
				{ value: 'IN', text: this.$t('state.in') },
				{ value: 'KS', text: this.$t('state.ks') },
				{ value: 'KY', text: this.$t('state.ky') },
				{ value: 'LA', text: this.$t('state.la') },
				{ value: 'MA', text: this.$t('state.ma') },
				{ value: 'MD', text: this.$t('state.md') },
				{ value: 'ME', text: this.$t('state.me') },
				{ value: 'MI', text: this.$t('state.mi') },
				{ value: 'MN', text: this.$t('state.mn') },
				{ value: 'MO', text: this.$t('state.mo') },
				{ value: 'MS', text: this.$t('state.ms') },
				{ value: 'MT', text: this.$t('state.mt') },
				{ value: 'NC', text: this.$t('state.nc') },
				{ value: 'ND', text: this.$t('state.nd') },
				{ value: 'NE', text: this.$t('state.ne') },
				{ value: 'NH', text: this.$t('state.nh') },
				{ value: 'NJ', text: this.$t('state.nj') },
				{ value: 'NM', text: this.$t('state.nm') },
				{ value: 'NV', text: this.$t('state.nv') },
				{ value: 'NY', text: this.$t('state.ny') },
				{ value: 'OH', text: this.$t('state.oh') },
				{ value: 'OK', text: this.$t('state.ok') },
				{ value: 'OR', text: this.$t('state.or') },
				{ value: 'PA', text: this.$t('state.pa') },
				{ value: 'RI', text: this.$t('state.ri') },
				{ value: 'SC', text: this.$t('state.sc') },
				{ value: 'SD', text: this.$t('state.sd') },
				{ value: 'TN', text: this.$t('state.tn') },
				{ value: 'TK', text: this.$t('state.tk') },
				{ value: 'UT', text: this.$t('state.ut') },
				{ value: 'VT', text: this.$t('state.vt') },
				{ value: 'WA', text: this.$t('state.wa') },
				{ value: 'WI', text: this.$t('state.wi') },
				{ value: 'WV', text: this.$t('state.wv') },
				{ value: 'WY', text: this.$t('state.wy') },
				{ value: 'VA', text: this.$t('state.va') }
			]
		};
	},
	computed: {
		/** If a label is not provided, use the component's built-in translated text. */
		addressLabel() {
			return this.label || this.$t('label.address');
		},
		isProvinceValid() {
			return this.province && this.province.toLowerCase() !== 'oc';
		},
		isStateValid() {
			return this.state && this.state.toLowerCase() !== 'oc';
		},
		isPostalCodeValid() {
			return this.postalCode && this.postalCode.toLowerCase() !== 'unknown';
		},
		isZipCodeValid() {
			return this.zipCode && this.zipCode.toLowerCase() !== 'unknown';
		},
		// Display the full province name instead of the abbreviation.
		displayProvince() {
			if (this.province) {
				const index = this.provinces.findIndex((province) => {
					return province.value === this.province;
				});
				return this.provinces[index]?.text;
			}
			return null;
		},
		// Display the full province name instead of the abbreviation.
		displayState() {
			if (this.state) {
				const index = this.states.findIndex((state) => {
					return state.value === this.state;
				});
				return this.states[index]?.text;
			}
			return null;
		},
		displayCountry() {
			const index = this.countries.findIndex((country) => {
				return country.value === this.country;
			});
			return this.countries[index]?.text;
		},
		displayPostalCode() {
			if (this.postalCode) {
				return `${this.postalCode.substring(0, 3)} ${this.postalCode.substring(3)}`;
			}
			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
/* 
  The <address> element is styled with a 1rem margin on the bottom in Bootstrap.  We need to override this to match the bottom spacing of the base display group component. 
*/
address {
	margin-bottom: 0;
}
</style>

<i18n>
{
  "en": {
    "label": {
      "address": "Address"
    },
	"province": {
			"ab": "Alberta",
			"bc": "British Columbia",
			"mb": "Manitoba",
			"nb": "New Brunswick",
			"nf": "Newfoundland and Labrador",
			"tno": "Northwest Territories",
			"ns": "Nova Scotia",
			"nu": "Nunavut",
			"on": "Ontario",
			"pei": "Prince Edward Island",
			"qc": "Quebec",
			"sk": "Saskatchewan",
			"yu": "Yukon"
	},
	"country": {
			"can": "Canada",
			"usa": "United States",
			"other": "Other"
	},
	"state": {
      "ak": "Alaska",
      "al": "Alabama",
      "ar": "Arkansas",
      "az": "Arizona",
      "ca": "California",
      "co": "Colorado",
      "ct": "Connecticut",
      "dc": "District of Columbia",
      "de": "Delaware",
      "fl": "Florida",
      "ga": "Georgia",
      "hi": "Hawaii",
      "ia": "Iowa",
      "id": "Idaho",
      "il": "Illinois",
      "in": "Indiana",
      "ks": "Kansas",
      "ky": "Kentucky",
      "la": "Louisiana",
      "ma": "Massachusetts",
      "md": "Maryland",
      "me": "Maine",
      "mi": "Michigan",
      "mn": "Minnesota",
      "mo": "Missouri",
      "ms": "Mississippi",
      "mt": "Montana",
      "nc": "North Carolina",
      "nd": "North Dakota",
      "ne": "Nebraska",
      "nh": "New Hampshire",
      "nj": "New Jersey",
      "nm": "New Mexico",
      "nv": "Nevada",
      "ny": "New York",
      "oh": "Ohio",
      "ok": "Oklahoma",
      "or": "Oregon",
      "pa": "Pennsylvania",
      "ri": "Rhode Island",
      "sc": "South Carolina",
      "sd": "South Dakota",
      "tn": "Tennessee",
      "tk": "Texas",
      "ut": "Utah",
      "vt": "Vermont",
      "wa": "Washington",
      "wi": "Wisconsin",
      "wv": "West Virginia",
      "wy": "Wyoming",
      "va": "Virginia"
    }
  },
  "fr": {
    "label": {
      "address": "Adresse"
    },
	"province": {
		"ab": "Alberta",
		"bc": "Colombie-Britannique",
		"pei": "Île-du-Prince-Édouard",
		"mb": "Manitoba",
		"nb": "Nouveau-Brunswick",
		"ns": "Nouvelle-Écosse",
		"nu": "Nunavut",
		"on": "Ontario",
		"qc": "Québec",
		"sk": "Saskatchewan",
		"nf": "Terre-Neuve-et-Labrador",
		"tno": "Territoires du Nord-Ouest",
		"yu": "Yukon"
	},
	"country": {
		"can": "Canada",
		"usa": "Étas-Unis",
		"other": "Autre"
	},
	"state": {
      "ak": "Alaska",
      "al": "Alabama",
      "ar": "Arkansas",
      "az": "Arizona",
      "ca": "California",
      "co": "Colorado",
      "ct": "Connecticut",
      "dc": "District of Columbia",
      "de": "Delaware",
      "fl": "Florida",
      "ga": "Georgia",
      "hi": "Hawaii",
      "ia": "Iowa",
      "id": "Idaho",
      "il": "Illinois",
      "in": "Indiana",
      "ks": "Kansas",
      "ky": "Kentucky",
      "la": "Louisiana",
      "ma": "Massachusetts",
      "md": "Maryland",
      "me": "Maine",
      "mi": "Michigan",
      "mn": "Minnesota",
      "mo": "Missouri",
      "ms": "Mississippi",
      "mt": "Montana",
      "nc": "North Carolina",
      "nd": "North Dakota",
      "ne": "Nebraska",
      "nh": "New Hampshire",
      "nj": "New Jersey",
      "nm": "New Mexico",
      "nv": "Nevada",
      "ny": "New York",
      "oh": "Ohio",
      "ok": "Oklahoma",
      "or": "Oregon",
      "pa": "Pennsylvania",
      "ri": "Rhode Island",
      "sc": "South Carolina",
      "sd": "South Dakota",
      "tn": "Tennessee",
      "tk": "Texas",
      "ut": "Utah",
      "vt": "Vermont",
      "wa": "Washington",
      "wi": "Wisconsin",
      "wv": "West Virginia",
      "wy": "Wyoming",
      "va": "Virginia"
    }
  }
}
</i18n>
