<template>
	<BaseCard automation-id="email" :title="$t('title')" title-tag="h2">
		<BCardText>
			{{ $t('text.emailUsedForLogin') }}
		</BCardText>

		<h3>{{ $t('label.currentEmail') }}</h3>
		<p>{{ email }}</p>

		<BaseButton
			class="mt-2"
			:label="$t('label.changeEmail')"
			:automation-id="getAutomationId('changeEmail')"
			data-testid="btn-change-email"
			@click="$emit('click')"
		/>
	</BaseCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BCardText } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';

export default {
	name: 'MemberEmail',
	components: {
		BCardText,
		BaseButton,
		BaseCard
	},
	mixins: [IdMixin],

	props: {
		email: {
			type: String,
			required: true
		}
	}
};
</script>

<i18n>
{
  "en": {
    "title": "Account email",
    "text": {
      "emailUsedForLogin": "This is the email you'll use to log into your account and how we'll communicate with you about your claims and coverage."
    },
    "label": {
      "currentEmail": "Current email",
      "changeEmail": "Change email"
    }
  },
  "fr": {
    "title": "Adresse courriel du compte",
    "text": {
      "emailUsedForLogin": "Cette adresse courriel est utilisée pour vous connecter à votre compte et recevoir nos communications au sujet de vos demandes de règlement et de votre couverture."
    },
    "label": {
      "currentEmail": "Adresse courriel actuelle",
      "changeEmail": "Modifier l’adresse courriel "
    }
  }
}
</i18n>

<style lang="scss" scoped>
h3 {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	margin: 0;
}
</style>
